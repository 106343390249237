/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { Option } from 'data/RecurringOptions'
import { Address } from 'utils/formatters'
import { Message, Offer } from './common'
import { plutoFormMutation, plutoMutation, plutoQuery, plutoQueryQuiet, stringify } from './gqlInterface'
import { PaginatedResponse, MutationResponse, Pagination, paginationString, mutationResponseString } from './types'

export type Activity = {
    id: string
    title: string
    message: string
    startPoint?: string
    imgURL: string
    receive: string
    value: number
    status?: string
    type: string
    spend?: number
    externalLink?: string
    basis: string
    resetPeriod?: string
    isCovert?: boolean
    pin?: string
    priority?: string
}

export type Goal = {
    id: string
    title: string
    value: number
}

export type MerchantRewardsProduct = {
    productID?: string
    productTerms?: string
    max: number
    activities: Omit<Activity, 'message' | 'startPoint' | 'receive' | 'status' | 'priority'>[]
    goals: Goal[]
    checkinOn?: boolean
    surveyOn?: boolean
    surveyDelay?: number
    surveyFrequency?: number
    cardBackground?: string
    defaultCardBackground?: string
    useDefaultCardBackground?: boolean
    cardColor?: string
    manualAwardOn?: boolean
    manualAwardMax?: number
    smsNotifyOn?: boolean
}
export const getMerchantRewardsProduct = async (accountID: string) => {
    const res = await plutoQuery<MerchantRewardsProduct>`
        merchantRewardsProduct(accountID: "${accountID}") {
            productID
            productTerms
            max
            activities {
                id
                type
                basis
                title
                spend
                value
                externalLink
                imgURL
                resetPeriod
                isCovert
                pin
            }
            goals {
                id
                title
                value
            }
            checkinOn
            surveyOn
            surveyDelay
            surveyFrequency
            cardBackground
            defaultCardBackground
            useDefaultCardBackground
            cardColor
            manualAwardOn
            manualAwardMax
            smsNotifyOn
        }
    `
    return res
}

type GetMerchantClaimResponse = {
    accounts: [{ claim: Omit<Message, 'status'> }]
}
export const getMerchantClaim = async (accountID: string, claimID: string) => {
    const res = await plutoQuery<GetMerchantClaimResponse>`
        contact {
            accounts(id:"${accountID}") {
                claim(id:"${claimID}") {
                    id
                    title
                    date
                    expiry
                    message
                    sender
                    read
                    attachments {
                        name
                        url
                        expiry
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.claim ?? null
}

type GetMerchantClaimsResponse = {
    accounts: [{ claims: PaginatedResponse<Omit<Message, 'status'>> }]
}
export const getMerchantClaims = async (accountID: string, pagination: Pagination, search: string, status: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantClaimsResponse>`
        contact {
            accounts(id:"${accountID}") {
                claims(pagination: ${pagination}, search: "${search}", status: ${status}, sort: ${sort}) {
                    set {
                        id
                        title
                        date
                        expiry
                        message
                        sender
                        read
                        status {
                            text
                            color
                        }
                        attachments {
                            name
                            url
                            expiry
                        }
                    }
                    pagination {
                        nextPage
                        prevPage
                        total
                        pageNavigation {
                            page
                            items
                            cursor
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.claims ?? null
}

type GetMerchantUnreadClaimsResponse = { accounts: { id: string; unreadClaims: number }[] }
export const getMerchantUnreadClaims = async () => {
    const res = await plutoQueryQuiet<GetMerchantUnreadClaimsResponse>`
        contact {
            accounts {
                id
                unreadClaims
            }
        }
    `
    return res?.accounts?.reduce((t, a) => ({ ...t, [a.id]: a.unreadClaims })) ?? {}
}

type GetMerchantRedemptionsResponse = {
    accounts: [{ redemptions: PaginatedResponse<Omit<Message, 'status'>> }]
}
export const getMerchantRedemptions = async (accountID: string, pagination: Pagination, search: string, status: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantRedemptionsResponse>`
        contact {
            accounts(id:"${accountID}") {
                redemptions(pagination: ${pagination}, search: "${search}", status: ${status}, sort: ${sort}) {
                    set {
                        id
                        title
                        date
                        expiry
                        message
                        sender
                        read
                        status {
                            text
                            color
                        }
                        attachments {
                            name
                            url
                            expiry
                        }
                    }
                    pagination {
                        nextPage
                        prevPage
                        total
                        pageNavigation {
                            page
                            items
                            cursor
                        }
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.redemptions ?? null
}

type GetMerchantUnreadRedemptionsResponse = { accounts: { id: string; unreadRedemptions: Record<string, number> }[] }
export const getMerchantUnreadRedemptions = async () => {
    const res = await plutoQueryQuiet<GetMerchantUnreadRedemptionsResponse>`
        contact {
            accounts {
                id
                unreadRedemptions
            }
        }
    `
    return res?.accounts?.reduce((t, a) => ({ ...t, [a.id]: a.unreadRedemptions })) ?? {}
}

export const getMerchantRewardsDefaultTerms = async (accountID: string) => plutoQuery<string>`
    merchantRewardsDefaultTerms(accountID: "${accountID}")
`

type GetMerchantRewardsDefaultTriggersResponse = Pick<Activity, 'type' | 'title' | 'basis' | 'value' | 'imgURL'>[]
export const getMerchantRewardsDefaultTriggers = async (accountID: string) => plutoQuery<GetMerchantRewardsDefaultTriggersResponse>`
    merchantRewardsDefaultTriggers(accountID: "${accountID}") {
        type
        title
        basis
        value
        imgURL
    }
`

export const getMerchantRewardsDataSummary = async (accountID: string) => plutoQuery<string>`
    merchantRewardsDataSummary(accountID: "${accountID}")
`

export const getMerchantCampaignSummary = async (accountID: string, campaignID: string) => plutoQuery<string>`
    merchantCampaignSummary(accountID: "${accountID}", campaignID: "${campaignID}")
`

export type MerchantStoreBrochure = {
    storeID: string | null
    storeName: string
    address?: Address
    brochures: {
        id: string
        name: string
        html: string
        isOrderable: boolean
    }[]
}
export const getMerchantStoreBrochures = async (accountID: string, productID: string) => plutoQuery<MerchantStoreBrochure[]>`
    merchantRewardsStoreBrochures(accountID: "${accountID}", productID: "${productID}") {
        storeID
        storeName
        brochures {
            id
            name
            html
            isOrderable
        }
    }
`

export type MerchantSocialAsset = {
    id: string
    title: string
    imgURL: string
    description?: string
}
type GetMerchantSocialAssetsResponse = {
    accounts: [{ holdings: [{ marketingSocialAssets: PaginatedResponse<MerchantSocialAsset> }] }]
}
export const getMerchantSocialAssets = async (accountID: string, holdingID: string, pagination: Pagination) => {
    const res = await plutoQuery<GetMerchantSocialAssetsResponse>`
    contact {
        accounts(id: "${accountID}") {
            holdings(id: "${holdingID}") {
                marketingSocialAssets(pagination: ${pagination}) {
                    set {
                        id
                        title
                        imgURL
                        description
                    }
                    ${paginationString}
                }
            }
        }
    }
`
    return res?.accounts[0]?.holdings?.[0].marketingSocialAssets ?? null
}

type RewardsCustomer = {
    id: string
    name: string
    email: string
    balance: number
}
export const getMerchantRewardsCustomers = async (accountID: string, pagination: Pagination, store?: string) => {
    const res = await plutoQuery<PaginatedResponse<RewardsCustomer>>`
        merchantRewardsCustomers(accountID: "${accountID}", pagination: ${pagination}${store ? `, store: "${store}"` : ''}) {
            set {
                id
                email
                name
                balance
            } 
            ${paginationString}
        }
    `
    return res
}

type UpdateMerchantAccountInput = {
    accountID: string
    accountName?: string
    accountTradingName?: string
    businessNumber?: string
    businessWebsite?: string
    businessIndustry?: string
    businessAddress?: {
        unit?: string
        number?: string
        streetName?: string
        suburb?: string
        postCode?: string
        city?: string
        state?: string
        country?: string
    }
}
export const updateMerchantAccount = async (input: UpdateMerchantAccountInput) => plutoMutation`
    updateMerchantAccount(input: ${input}) {
        success
        message
    }
`

type GetSubscriptionSessionIDResponse = MutationResponse & { sessionID?: string }
export const getSubscriptionSessionID = async (input: { accountID: string }) => plutoMutation<GetSubscriptionSessionIDResponse>`
    createSubscriptionCustomerAndSession(input: ${input}) {
        success
        message
        sessionID
    }
`

export const getSubscriptionCustomerPortalLink = async (accountID: string) => plutoQuery<string>`
    getSubscriptionCustomerPortalLink(accountID: "${accountID}")
`

type AddStaffInput = {
    accountID: string
    firstName: string
    lastName: string
    email: string
    phone?: string
    role: string
    storeID?: string
    identityFile?: {
        classification: string
        number: string
    }
}
export const addStaff = async (input: AddStaffInput, identityFile?: File) =>
    plutoFormMutation(
        `
            mutation($identityFile: Upload) {
                addStaff(input: ${stringify(input)}, identityFile: $identityFile) ${mutationResponseString}
            }
        `,
        identityFile ? { identityFile } : {}
    )

type EditStaffInput = {
    accountID: string
    contactID: string
    role: string
    storeID?: string
    identityFile?: {
        classification: string
        number: string
    }
}
export const editStaff = async (input: EditStaffInput, identityFile?: File) =>
    plutoFormMutation(
        `
            mutation($identityFile: Upload) {
                editStaff(input: ${stringify(input)}, identityFile: $identityFile) ${mutationResponseString}
            }
        `,
        identityFile ? { identityFile } : {}
    )

type UnlinkStaffInput = {
    accountID: string
    contactID: string
}
export const unlinkStaff = async (input: UnlinkStaffInput) => plutoMutation`
    unlinkStaff(input: ${input}) {
        success
        message
    }
`

type AddStoreInput = {
    accountID: string
    name: string
    phone: string
    bookingURL: string
    address: {
        unit?: string
        number?: string
        streetName: string
        suburb: string
        city: string
        postCode: string
        state: string
        country: string
        geo: {
            lat: number
            long: number
        }
    }
}
export const addStore = async (input: AddStoreInput) => plutoMutation`
    manageStore(input: ${input}) {
        success
        message
    }
`

type EditStoreInput = {
    accountID: string
    storeID: string
    name: string
    phone: string
    bookingURL: string
    address: {
        unit?: string
        number?: string
        streetName: string
        suburb: string
        city: string
        postCode: string
        state: string
        country: string
        geo: {
            lat: number
            long: number
        }
    }
}
export const editStore = async (input: EditStoreInput) => plutoMutation`
    manageStore(input: ${input}) {
        success
        message
    }
`

type UnlinkStoreInput = {
    accountID: string
    storeID: string
}
export const unlinkStore = async (input: UnlinkStoreInput) => plutoMutation`
    deleteStore(input: ${input}) {
        success
        message
    }
`

type ReferHealthCoverInput = {
    accountID?: string
    accountName?: string
    staffName?: string
    holdingID?: string
    firstName: string
    lastName: string
    phone: string
    email: string
    state: string
    provinceID: string
    currentHealthFund: string
    currentHealthFundID: string
}
export const referHealthCover = async (input: ReferHealthCoverInput) => plutoMutation`
    healthCoverReferral(input: ${input}) {
        success
        message
    }
`

type Checkin = {
    id: string
    date: string
    name: string
    phone: string
    store?: string
}
type GetMerchantCheckinsResponse = {
    accounts: [{ checkins: PaginatedResponse<Checkin> }]
}
export const getMerchantCheckins = async (accountID: string, pagination: Pagination, store?: string) => {
    const res = await plutoQuery<GetMerchantCheckinsResponse>`
        contact {
            accounts(id: "${accountID}") {
                checkins(pagination: ${pagination}${store ? `, store: "${store}"` : ''}) {
                    set {
                        id
                        name
                        phone
                        date
                        store
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.checkins ?? null
}

type CustomerLookupResponse = {
    contactID: string
    holdingID: string
    name: string
}[]
export const customerLookup = async (merchantAccountID: string, search: string) => {
    const res = await plutoQuery<CustomerLookupResponse>`
        customerLookup(merchantAccountID: "${merchantAccountID}", search: "${search}") {
            contactID
            holdingID
            name
        }
    `
    return res?.map((c) => ({ title: c.name, id: c.contactID, ...c })) ?? []
}

type MerchantCheckinInput = {
    merchantAccountID: string
    contactID: string
    holdingID: string
    storeID: string
}
export const merchantCheckin = async (input: MerchantCheckinInput) => plutoMutation`
    merchantCheckin(input: ${input}) {
        success
        message
    }
`

type MerchantManualAwardInput = {
    accountID: string
    holdingID: string
    consumerHoldingID: string
    amount: number
    description?: string
}
export const manualAward = async (input: MerchantManualAwardInput) => plutoMutation`
    manualAward(input: ${input}) {
        success
        message
    }
`

export type Store = {
    id: string
    name: string
    phone?: string
    bookingURL?: string
    address?: Address
}
export type Campaign = {
    id: string
    status: string
    name: string
    start: string
    end: string
    enableSMS: boolean
    offers: Offer[]
}
type GetMerchantCampaignsResponse = {
    accounts: [{ campaigns: PaginatedResponse<Campaign> }]
}
export const getMerchantCampaigns = async (accountID: string, pagination: Pagination, statuses: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantCampaignsResponse>`
        contact {
            accounts(id: "${accountID}") {
                campaigns(pagination: ${pagination}, status: ${statuses}, sort: ${sort}) {
                    set {
                        id
                        status
                        name
                        start
                        end
                        enableSMS
                        offers {
                            id
                            campaignID
                            title
                            description
                            expiryDate
                            activationLastDate
                            activationLastDatePeriod
                            stores {
                                id
                                name
                            }
                            maxActivations
                            audience
                            imageURL
                            productName
                            productID
                            statistics {
                                prospects
                                clicks
                                activations
                                redemptions
                            }
                        }
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.campaigns ?? null
}

type GetMerchantCampaignResponse = {
    accounts: [{ campaign: Campaign }]
}
export const getMerchantCampaign = async (accountID: string, campaignID: string) => {
    const res = await plutoQuery<GetMerchantCampaignResponse>`
        contact {
            accounts(id: "${accountID}") {
                campaign(id: "${campaignID}") {
                    id
                    status
                    name
                    start
                    end
                    enableSMS
                    offers {
                        id
                        campaignID
                        title
                        description
                        expiryDate
                        activationLastDate
                        activationLastDatePeriod
                        stores {
                            id
                            name
                        }
                        maxActivations
                        audience
                        imageURL
                        productName
                        productID
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.campaign ?? null
}

type AddCampaignInput = {
    accountID: string
    name: string
    start: string
    end: string
    enableSMS?: boolean
    offers: {
        title: string
        description: string
        expiryDate: string
        activationLastDate?: string
        activationLastDatePeriod?: number
        stores: string[]
        maxActivations?: number
        audience?: number
        productID?: string
        imageIndex?: number
    }[]
}
export const addCampaign = async (input: AddCampaignInput, offerImages: File[]) =>
    offerImages.length > 0
        ? plutoFormMutation(
              `
            mutation($offerImages: [Upload!]!) {
                manageCampaign(input: ${stringify(input)}, offerImages: $offerImages) {
                    success
                    message
                }
            }
        `,
              { offerImages }
          )
        : plutoMutation`
            manageCampaign(input: ${stringify(input)}) {
                success
                message
            }
`

type EditCampaignInput = {
    campaignID: string
    accountID: string
    name: string
    start: string
    end: string
    enableSMS: boolean
    offers: {
        offerID?: string
        title: string
        description: string
        expiryDate: string
        activationLastDate?: string
        activationLastDatePeriod?: number
        stores: string[]
        maxActivations?: number
        audience?: number
        productID?: string
        imageIndex?: number
    }[]
}
export const editCampaign = async (input: EditCampaignInput, offerImages: File[]) =>
    offerImages.length > 0
        ? plutoFormMutation(
              `
            mutation($offerImages: [Upload!]!) {
                manageCampaign(input: ${stringify(input)}, offerImages: $offerImages) {
                    success
                    message
                }
            }
        `,
              { offerImages }
          )
        : plutoMutation`
            manageCampaign(input: ${stringify(input)}) {
                success
                message
            }
        `

type CancelCampaignInput = {
    campaignID: string
    accountID: string
}
export const cancelCampaign = async (input: CancelCampaignInput) => plutoMutation`
    cancelCampaign(input: ${input}) {
        success
        message
    }
`

type GetMerchantOfferEnabledProductsResponse = {
    accounts: [{ offerEnabledProducts: Omit<Option, 'key'>[] }]
}
export const getMerchantOfferEnabledProducts = async (accountID: string) => {
    const res = await plutoQuery<GetMerchantOfferEnabledProductsResponse>`
        contact {
            accounts(id: "${accountID}") {
                offerEnabledProducts {
                    text
                    value
                }
            }
        }
    `
    return res?.accounts[0]?.offerEnabledProducts ?? []
}

export const markOfferActivationConsumed = async (input: { accountID: string; activationID: string }) => plutoMutation`
    consumeOfferActivation(input: ${input}) {
        success
        message
    }
`

type MarketingEmailTemplate = {
    id: string
    name: string
    content: string
    plain: string
}
type GetMerchantEmailTemplatesResponse = {
    accounts: [{ holdings: [{ marketingEmailTemplates: PaginatedResponse<MarketingEmailTemplate> }] }]
}
export const getMerchantEmailTemplates = async (accountID: string, holdingID: string, pagination: Pagination, storeID: string) => {
    const res = await plutoQuery<GetMerchantEmailTemplatesResponse>`
    contact {
        accounts(id: "${accountID}") {
            holdings(id: "${holdingID}") {
                marketingEmailTemplates(pagination: ${pagination}, store: ${storeID}) {
                    set {
                        id
                        name
                        content
                        plain
                    }
                    ${paginationString}
                }
            }
        }
    }
`
    return res?.accounts[0]?.holdings?.[0].marketingEmailTemplates ?? null
}

type MarketingSMSTemplate = {
    id: string
    name: string
    plain: string
}
type GetMerchantSMSTemplatesResponse = {
    accounts: [{ holdings: [{ marketingSMSTemplates: PaginatedResponse<MarketingSMSTemplate> }] }]
}
export const getMerchantSMSTemplates = async (accountID: string, holdingID: string, pagination: Pagination, storeID: string) => {
    const res = await plutoQuery<GetMerchantSMSTemplatesResponse>`
    contact {
        accounts(id: "${accountID}") {
            holdings(id: "${holdingID}") {
                marketingSMSTemplates(pagination: ${pagination}, store: ${storeID}) {
                    set {
                        id
                        name
                        plain
                    }
                    ${paginationString}
                }
            }
        }
    }
`
    return res?.accounts[0]?.holdings?.[0].marketingSMSTemplates ?? null
}

export type BrochurePurchaseOption = {
    id: string
    name: string
    description?: string
    price: number
}
export const getMerchantBrochurePurchaseOptions = async (accountID: string) => plutoQuery<BrochurePurchaseOption[]>`
    merchantBrochurePurchaseOptions(accountID: "${accountID}") { id name description price }
`

type CreateMerchantAccountInput = {
    businessName: string
    tradingName: string
    governmentID: string
    trustType?: string
    trusteeFirstName?: string
    trusteeMiddleNames?: string
    trusteeLastName?: string
    trusteeDOB?: string
    trusteeAddress?: Address
    trusteeRegistration?: string
    trusteeLegalName?: string
    firstName: string
    middleNames?: string
    lastName?: string
    lastNameSecond?: string
    suffix?: string
    dob?: string
    phone: string
    address?: Address
    email: string
    position: string
    businessLine: string
    founded: string
    businessType: string
    entityType: string
    businessAddress: Address
    acceptedTerms: string[]
    dynamicInputs: Record<string, string | Record<string, string>>
}
export const createMerchantAccount = async (input: CreateMerchantAccountInput, dynamicFiles: File[]) =>
    plutoFormMutation(
        `
            mutation($dynamicFiles: [Upload!]!) {
                createMerchantAccount(input: ${stringify(input)}, dynamicFiles: $dynamicFiles) ${mutationResponseString}
            }
        `,
        { dynamicFiles }
    )

export type DealOffer = {
    id: string
    lender: string
    lenderID: string
    status: 'Draft' | 'Issued' | 'Viewed' | 'Declined' | 'Accepted' | 'Lapsed' | 'Completed'
    amount: number
    currency: string
    term: number
    structure?: string
    balloon?: number
    securityType: string
    expiry?: string
    conditions: string[]
    fees: number
    repaymentAmount: number
    repaymentNumber: number
    repaymentFrequency: 'Monthly' | 'Fortnightly'
}
export type DealLender = {
    id: string
    status: 'Matched' | 'Accepted' | 'Declined'
    estimatedOfferDays?: number
}
export type Deal = {
    id: string
    usage: string
    usageName: string
    usageImage: string
    creationDate: string
    requiredDate?: string
    displayStatus: 'Draft' | 'In progress' | 'Offers available' | 'Offers lapsed' | 'Completed' | 'Closed'
    status?: string
    amount: number
    term: number
    balloon?: number
    offers: PaginatedResponse<DealOffer>
    lenders: DealLender[]
}
type GetMerchantDealsResponse = {
    accounts: [{ deals: PaginatedResponse<Deal> }]
}
export const getMerchantDeals = async (accountID: string, pagination: Pagination, statuses: string[], sort: string) => {
    const res = await plutoQuery<GetMerchantDealsResponse>`
        contact {
            accounts(id: "${accountID}") {
                deals(pagination: ${pagination}, status: ${statuses.map((v) => `"${v}"`)}, sort: ${sort}) {
                    set {
                        id
                        usage
                        usageName
                        usageImage
                        creationDate
                        requiredDate
                        displayStatus
                        status
                        amount
                        term
                        balloon
                        offers(pagination: {limit:50}) {
                            set {
                                id
                                lender
                                lenderID
                                status
                                amount
                                currency
                                term
                                structure
                                balloon
                                securityType
                                expiry
                                conditions
                            }
                            ${paginationString}
                        }
                        lenders {
                            id
                            status
                            estimatedOfferDays
                        }
                    }
                    ${paginationString}
                }
            }
        }
    `
    return res?.accounts[0]?.deals ?? null
}

type GetMerchantDealResponse = {
    accounts: [{ deal: Deal }]
}
export const getMerchantDeal = async (accountID: string, dealID: string, pagination: Pagination) => {
    const res = await plutoQuery<GetMerchantDealResponse>`
        contact {
            accounts(id: "${accountID}") {
                deal(id: "${dealID}") {
                    id
                    usage
                    usageName
                    usageImage
                    creationDate
                    requiredDate
                    displayStatus
                    status
                    amount
                    term
                    balloon
                    offers(pagination: ${pagination}) {
                        set {
                            id
                            lender
                            lenderID
                            status
                            amount
                            currency
                            term
                            structure
                            balloon
                            securityType
                            expiry
                            conditions
                            fees
                            repaymentAmount
                            repaymentNumber
                            repaymentFrequency
                        }
                        ${paginationString}
                    }
                    lenders {
                        id
                        status
                        estimatedOfferDays
                    }
                }
            }
        }
    `
    return res?.accounts[0]?.deal ?? null
}

type UpdateMerchantDealOfferStatusInput = {
    accountID: string
    dealOfferID: string
    status: DealOffer['status']
}
export const updateMerchantDealOfferStatus = async (input: UpdateMerchantDealOfferStatusInput) =>
    plutoMutation`updateDealOfferStatus(input: { accountID: "${input.accountID}", dealOfferID: "${input.dealOfferID}", status: ${input.status}}) ${mutationResponseString}`
